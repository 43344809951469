<template>
  <div>
    <p>
      
    </p>
    <div :class="`mb-10 ${$vuetify.breakpoint.xs?'':'indent'}`">
      <h1 class="text-center" style="position:relative">
        Pricing
        <v-btn-toggle v-model="currency" mandatory class="multiline-btns" style="position:absolute;right:0;top:-45px;" >
          <v-btn v-for="cur in currencies" :key="cur" :value="cur" small>
            {{cur}}
          </v-btn>
        </v-btn-toggle>
      </h1>
      <div :class="{'align-center': true, 'indent':!$vuetify.breakpoint.xs }">
        <p>We like to keep things simple. Pricing scales with the size of your event and the support plan that best fits you.</p>
        <p>Use our price calculator to get a personalized offer for your organization.</p>
      </div>
      <PriceCalculatorDialog ref="calculator" />
      <div v-if="false">
        <v-btn text @click="()=>$refs.calculator.open()">Open Calculator</v-btn>
      </div>
    </div> 
    <v-sheet v-if="!hideInlineCalculator" elevation="1" class="price-summary price-side-bar grey lighten-4 pa-3 d-none d-md-block">
      <h3 class="accent--text">Our Personalized Offer</h3>
      <v-divider v-if="false" class="mt-2 mb-3" />
      <div class="features mt-2">
        <p v-if="selectedMode=='event'"><v-icon color="accent" small class="me-1">fa-check</v-icon>1.  <a class="select-option" @click="highlightMode"><strong>On-Demand Event</strong></a></p>
        <p v-if="selectedMode=='subscription'"><v-icon color="accent" small class="me-1">fa-check</v-icon>1.  <a class="select-option" @click="highlightMode"><strong>Unlimited Events</strong></a></p>
        <p><v-icon color="accent" small class="me-1">fa-check</v-icon>2. <a class="select-option" @click="highlightUsers"><strong>{{amount}}</strong> users</a></p>
        <p v-if="selectedPlan"><v-icon color="accent" small class="me-1">fa-check</v-icon>3. <strong>{{ selectedPlan.license | license }}</strong> plan</p>
        <p v-if="!selectedPlan"><v-icon color="grey" small class="me-1">fa-chevron-right</v-icon>3. <a class="select-option" @click="highlightPlan">Select <strong>Plan</strong></a></p>
        <p v-if="selectedSupportPlan"><v-icon color="accent" small class="me-1">fa-check</v-icon>4. <strong>{{selectedSupportPlan.name}}</strong></p>
        <p v-if="!selectedSupportPlan"><v-icon color="grey" small class="me-1">fa-chevron-right</v-icon>4. <a class="select-option" @click="highlightSupport">Select <strong>Support Option</strong></a></p>
        <!-- <div v-if="selectedMode=='event'">
          <p v-if="!dataRetention"><v-icon color="grey" small>fa-chevron-right</v-icon> <a class="select-option" @click="highlightDataRetention">Select Data Retention</a></p>
          <p v-if="dataRetention == 'NO'"><v-icon color="grey" small>fa-times</v-icon> No data retention</p>
          <p v-if="dataRetention == 'YES'"><v-icon color="accent" small>fa-check</v-icon> Ongoing data retention</p>
        </div> -->
      </div>
      <v-divider class="my-4" />
      <div v-if="!selectedPlan || !selectedSupportPlan" class="">
        <!-- <div class="text-muted">Starting From</div> -->
        <span class="small mr-1">{{currency}}</span><span class="large">{{minimumFee | int}}</span>
        <span v-if="selectedMode=='subscription'" class="small">per<br/>month</span>
      </div>
      <div v-else-if="selectedMode=='event'" class="">
        <div class="text-muted">Total Fee</div>
        <span class="small mr-1">{{currency}}</span><span class="large">{{totalFee | int}}</span>
        <p v-if="dataRetention == 'YES'" class="small">+ {{currency}} {{getProductPrice('subscription-data-retention') }} per user per month</p>
      </div>
      <div v-else class="">
        <div class="text-muted">One-Time Fee</div>
        <span class="small mr-1">{{currency}}</span><span class="large">{{oneTimeFee | int}}</span>
        <div class="mt-4 text-muted">Monthly Fee</div>
        <span class="small mr-1">{{currency}}</span><span class="large">{{perUserFee*amount | int}}</span>
      </div>
      <div class="mt-4">
        <v-btn v-if="!selectedPlan" color="grey darken-2" dark block class="my-0" @click="highlightPlan">Next</v-btn>
        <v-btn v-else-if="!selectedSupportPlan" color="grey darken-2" dark block class="my-0" @click="highlightSupport">Next</v-btn>
        <div v-else>
          <v-btn v-if="plan=='ENTERPRISE'" :to="{name:'contact'}" color="accent" block class="my-0">Talk To Us</v-btn>
          <v-btn v-else :to="createEventRouteInfo" block color="accent" class="my-0">Start Now</v-btn>
          <v-btn v-if="plan=='ENTERPRISE' || totalFee > 1000" outlined block color="accent" class="mt-4 mb-0" @click="requestQuote">Request Quote</v-btn>
        </div>
      </div> 
      <v-divider class="my-4" />
      <v-btn color="accent" outlined block small to="/start-trial">Start Free Trial</v-btn>
      <p class="text-muted text-center mt-2 mb-0">
        Up to 5 users and 14 days. No credit card needed.
      </p>
      <!-- <v-divider class="my-4" />
      <v-btn class="" color="primary" text block to="/contact">Talk to Us</v-btn> -->
    </v-sheet>
   
      <v-row class="align-center lighten-4" ref="modeSection">
        <v-col cols="12" class="">
          <h3 class="mb-0 pb-0">1. Flexibility &amp; Frequency</h3>
          <!-- <p class="text-muted mb-0">For how long is you event active?</p>  -->
        </v-col>
        <v-col cols="12" class="price-component-container">
          <v-sheet 
            elevation="1"
            :class="{ 'price-component grey lighten-4' : true, 'selected' : selectedMode=='event' }"
            @click="selectedMode='event'"
            >
              <span class="selected-marker"><v-icon color="green" x-small>fa-check</v-icon></span>
              <h3>On-Demand</h3>
              <p>For one-time or infrequent events (up to 45 days <simple-tooltip color="black" text="We only count the number of days your event is active. Preparation and wrap-up time does not count towards the limit. Extended duration events are possible at an extra."/>).</p>
          </v-sheet>
          <v-sheet 
            elevation="1"
            :class="{ 'price-component grey lighten-4' : true, 'selected' : selectedMode=='subscription' }"
            @click="selectedMode='subscription'"
            >
              <span class="selected-marker"><v-icon color="green" x-small>fa-check</v-icon></span>
              <h3>Unlimited</h3>
              <p>For ongoing or multiple events.</p>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row class="align-center lighten-4" ref="usersSection">
        <v-col cols="12" class=" mb-0 pb-0">
          <h3 class="mb-0 pb-0">2. How many users do you expect?</h3>
          <p class="text-muted mb-0">Add more users anytime.</p>
        </v-col>
        <v-col cols="0" md="2"></v-col>
        <v-col cols="12" md="8">
          <v-slider 
            v-model="amount"
            min="10"
            max="1000"
            thumb-label
            step="10"
            class="grow"
            hide-details
            />
          <p v-if="amount >= 1000" class="">
            <router-link to="/contact">Request a quote</router-link> for larger events (volume discount may apply).
          </p>
        </v-col>
        <v-col cols="0" md="2"></v-col>
        <v-col cols="12" class="align-center">
          <v-sheet class="dotted pa-4" style="margin:0 auto;" max-width="200px">{{amount}} users</v-sheet>
        </v-col>
      </v-row>

      <div ref="planSection" class="scroll-section lighten-4">
      <div class="align-center mb-4">
        <h3 class="pt-8 mb-0 pb-0">3. Select your plan</h3>
        <p class="text-muted mb-0">Select the features you need for your event.</p>
      </div>
      <v-row class="mb-8 mx-0 pricing-details ">
        <v-col cols="12" class="price-component-container">
          <v-sheet elevation="1" :class="{ 'price-component grey lighten-4' : true, 'selected' : plan=='BASIC' }" @click="plan='BASIC'">
            <span class="selected-marker"><v-icon color="green" x-small>fa-check</v-icon></span>
            <h3 class="text-center">Starter</h3>
            <p>All the good stuff you need to host a successful fitness challenge.</p>
            <hr/>
            <div v-if="selectedMode=='event'">
              <span class="small mr-1">{{currency}}</span><span class="large">{{products.find(x=>x.key=='product-event-basic').prices[currency]}}</span><span class="small">per user <br/> per event</span>
            </div>
            <div v-if="selectedMode=='subscription'">
              <span class="small mr-1">{{currency}}</span><span class="large">{{products.find(x=>x.key=='subscription-month-basic').prices[currency]}}</span><span class="small">per user<br/>per month<br/>annual agreement</span>
            </div>
            <!-- <v-btn outlined color="green" to="/eventmanager/create?license=BASIC">Start Now</v-btn> -->
            <hr />
            <ul class="check-list">
              <li v-if="selectedMode=='subscription'">Unlimited events</li>
              <li v-if="selectedMode=='subscription'">User-created challenges</li>
              <li>All challenge modes</li>
              <li>All device integrations</li>
              <li>Team leaderboards</li>
              <li>Virtual course maps</li>
              <li>Self-service dashboard to manage events</li>
            </ul>
            
          </v-sheet>
          <v-sheet elevation="1" :class="{ 'price-component grey lighten-4' : true, 'selected' : plan=='PRO' }" @click="plan='PRO'">
            <span class="selected-marker"><v-icon color="green" x-small>fa-check</v-icon></span>
            <v-chip color="primary" class="most-popular">Most Popular</v-chip>
            <h3 class="text-center">Pro</h3>
            <p>Advanced features to host engaging events for larger teams.</p>
            <hr/>
            <div v-if="selectedMode=='event'">
              <span class="small mr-1">{{currency}}</span><span class="large">{{products.find(x=>x.key=='product-event-pro').prices[currency]}}</span><span class="small">per user<br/>per event</span>
            </div>
            <div v-if="selectedMode=='subscription'">
              <span class="small mr-1">{{currency}}</span><span class="large">{{products.find(x=>x.key=='subscription-month-pro').prices[currency]}}</span><span class="small">per user<br/>per month<br/>annual agreement</span>
            </div>
            <!-- <v-btn color="green" to="/eventmanager/create?license=PRO">Start Now</v-btn> -->
            <hr />
            <div class="align-left">All from <strong>Starter</strong>, plus:</div>
            <ul class="check-list">
              <li>Customizable branding</li>
              <li>Automatic step conversions</li>
              <li>Microsoft Teams Integration</li>
              <li>Slack Integration</li>
              <li>Excel Report Export</li>
              <li>Multiple co-admins</li>
            </ul>
          </v-sheet>
          <v-sheet elevation="1" :class="{ 'price-component grey lighten-4' : true, 'selected' : plan=='ENTERPRISE' }" @click="plan='ENTERPRISE'">
            <span class="selected-marker"><v-icon color="green" x-small>fa-check</v-icon></span>
            <h3 class="text-center">Enterprise</h3>
            <p>Enhanced security, SSO, integrations, security checklists, and DPAs.</p>
            <hr/>
            <div v-if="selectedMode=='event'">
            <span class="small mr-1">{{currency}}</span><span class="large">{{products.find(x=>x.key=='product-event-enterprise').prices[currency]}}</span><span class="small">per user<br/>per event</span>
            </div>
            <div v-if="selectedMode=='subscription'">
            <span class="small mr-1">{{currency}}</span><span class="large">{{products.find(x=>x.key=='subscription-month-enterprise').prices[currency]}}</span><span class="small">per user<br/>per month<br/>annual agreement</span>
            </div>
            <!-- <v-btn color="green" to="/contact">Talk to us</v-btn> -->
            <hr />
            <div class="align-left">All from <strong>PRO</strong>, plus:</div>
            <ul class="check-list">
              <li>Single Sign-On</li>
              <li>Custom DPA</li>
              <li>Security Checklists</li>
              <li>API and Zapier support</li>
              <li>Advanced security features</li>
            </ul>
          </v-sheet>
        </v-col>
        <v-col class="align-center">
          <!-- <v-btn v-show="showPlanDetails" text color="primary" @click="showPlanDetails = false">Hide plan features</v-btn>
          <v-btn v-show="!showPlanDetails" text color="primary" @click="showPlanDetails = true">Show plan features</v-btn> -->
          <v-btn outlined rounded color="primary" href="#comparison">Show detailed plan comparison</v-btn>
        </v-col>
      </v-row>    
      </div>

      <div ref="supportSection" class="scroll-section lighten-4">
      <v-row  class="align-center">
        <v-col cols="12" class="mb-4 pb-0">
          <h3 class="mb-0 pb-0">4. Support Plan</h3>
          <p class="text-muted mb-0">How can we best help you? You can always upgrade later on. <router-link to="/pricing/support-plans">Help me choose</router-link> </p>
        </v-col>
        <v-col cols="12" class="price-component-container">
          <p v-if="selectedMode=='subscription' && support != 'SELF_SERVICE'" class="mb-0 mt-4 text-center" style="flex-basis:100%;"><v-icon>fal fa-info-circle</v-icon> With Unlimited plans, the service plan applies to the first event. You can always request additional support for future events at any time.</p>
          <p v-if="plan=='ENTERPRISE'" class="mb-0 mt-4 text-center" style="flex-basis:100%;"><v-icon>fal fa-info-circle</v-icon> Enterprise plans require a support plan.</p>
          <v-sheet 
            elevation="1"
            :class="{ 'price-component grey lighten-4' : true, 'selected' : support=='SELF_SERVICE', 'disabled': plan == 'ENTERPRISE' }"
            @click="support='SELF_SERVICE'"
            >
              <span class="selected-marker"><v-icon color="green" x-small>fa-check</v-icon></span>
              <h3>Self-Service</h3>
              <p>Do the setup yourself. Our knowledge base will guide you.</p>
          </v-sheet>
          <v-sheet 
            elevation="1"
            :class="{ 'price-component grey lighten-4' : true, 'selected' : support=='SETUP_SERVICE' }"
            @click="support='SETUP_SERVICE'"
            >
              <span class="selected-marker"><v-icon color="green" x-small>fa-check</v-icon></span>
              <h3>Setup-Service</h3>
              <p>We'll do the setup and a training session to get you up-and-running in no time!</p>
              <v-divider/>
            <span class="small mr-1">{{currency}}</span><span class="large">{{getProductPrice('product-setup-service') | int}}</span>
          </v-sheet>
          <v-sheet 
            elevation="1"
            :class="{ 'price-component grey lighten-4' : true, 'selected' : support=='FULL_SERVICE' }"
            @click="support='FULL_SERVICE'"
            >
              <span class="selected-marker"><v-icon color="green" x-small>fa-check</v-icon></span>
              <h3>Full-Service</h3>
              <p>We've got your back! More support and training sessions for larger organizations.</p>
              <v-divider/>
            <span class="small mr-1">{{currency}}</span><span class="large">{{getProductPrice('product-full-service') | int}}</span>
          </v-sheet>

        </v-col>
      </v-row>
      </div>
<!-- 
      <v-row v-if="selectedMode=='event'" class="scroll-section lighten-4" ref="dataRetentionSection">
        <v-col cols="12" class="mb-0 pb-0">
          <h3 class="mb-0 pb-0">5. Data Retention?</h3>
          <p class="text-muted mb-0">By default we delete all results and accounts 30 days after the event ends to ensure the privacy of your team's data. Optionally, we can retain the data, making it much easier to re-engage your employees when new events are organized in the future!</p>
        </v-col>
        <v-col cols="12" class="price-component-container">
          <v-sheet 
            elevation="1"
            :class="{ 'price-component grey lighten-4' : true, 'selected' : dataRetention == 'NO' }"
            @click="dataRetention='NO'"
            >
              <span class="selected-marker"><v-icon color="green" x-small>fa-check</v-icon></span>
              <h3>No data retention</h3>
              <v-icon>fa-eye-slash</v-icon>
              <p>All results and accounts are removed 30 days after an event ends.</p>
          </v-sheet>
          <v-sheet 
            elevation="1"
            :class="{ 'price-component grey lighten-4' : true, 'selected' : dataRetention == 'YES' }"
            @click="dataRetention='YES'"
            >
              <span class="selected-marker"><v-icon color="green" x-small>fa-check</v-icon></span>
              <h3>Ongoing data retention</h3>
              <v-icon>fa-history</v-icon>
              <p>Keep all results and accounts from past events. Allow users to track progress and achievements between events.</p>
              <v-divider/>
            <span class="small mr-1">{{currency}}</span><span class="large">{{getProductPrice('subscription-data-retention')}}</span><span class="small">per user <br/> per month</span>
          </v-sheet>
        </v-col>
      </v-row> -->

      <!-- <v-divider /> -->
<!-- 
      <v-alert outlined type="info" class="mt-8 mb-4">
        <v-row dense>
          <v-col class="grow">
          <p>Try our <strong>free trial</strong> and test it out with up to 5 team members for 14 days.</p>
          </v-col>
          <v-col class="shrink">
            <v-btn outlined to="/eventmanager/create" color="info">Start FREE trial</v-btn>
          </v-col>
        </v-row>
      </v-alert> -->
    <v-divider class="my-12" />

    <div class="grey lighten-2 mt-12 price-summary price-summary-bottom" ref="inlineCalculator" @click="hideCalculatorDetails=!hideCalculatorDetails">
      <div class="container">
        <v-row>
          <v-col cols="12" class="section-title align-center d-flex accent--text">
            <h3 class="grow">Our Personalized Offer</h3>
            <div class="d-block d-md-none shrink">
              <v-btn icon><v-icon color="accent">{{ hideCalculatorDetails ? 'fa-angle-up' : 'fa-angle-down' }}</v-icon></v-btn>
            </div>
          </v-col>
          <v-col v-if="hideCalculatorDetails" cols="12" class="d-block d-md-none py-0 text-center">
            <!-- Mini summary in collapsed state -->
            <div v-if="!selectedPlan || !selectedSupportPlan" class="">
              <span class="small mr-1">Starting From</span>
              <span class="small mr-1">{{currency}}</span><span class="large">{{minimumFee | int}}</span>
              <span v-if="selectedMode=='subscription'" class="small">per<br/>month</span>
            </div>
            <div v-else-if="selectedMode=='event'" class="">
              <span class="small mr-1">Total Fee</span>
              <span class="small mr-1">{{currency}}</span><span class="large">{{totalFee | int}}</span>
              <p v-if="dataRetention == 'YES'" class="small">+ {{currency}} {{getProductPrice('subscription-data-retention') }} per user per month</p>
            </div>
            <div v-else class="">
              <span class="small mr-1">Monthly Fee</span>
              <span class="small mr-1">{{currency}}</span><span class="large">{{perUserFee*amount | int}}</span>
            </div>
          </v-col>
          <v-col v-if="!hideCalculatorDetails || $vuetify.breakpoint.mdAndUp" cols="8" md="4">
            <div class="features">
              <p v-if="selectedMode=='event'"><v-icon color="accent" small class="me-1">fa-check</v-icon>1. <strong>On-Demand Event</strong></p>
              <p v-if="selectedMode=='subscription'"><v-icon color="accent" small class="me-1">fa-check</v-icon>1. <strong>Unlimited Events</strong></p>
              <p><v-icon color="accent" small class="me-1">fa-check</v-icon>2. <strong>{{amount}}</strong> users</p>
              <p v-if="selectedPlan"><v-icon color="accent" small class="me-1">fa-check</v-icon>3. <strong>{{ selectedPlan.license | license }}</strong> plan</p>
              <p v-if="!selectedPlan"><v-icon color="grey" small class="me-1">fa-chevron-right</v-icon>3. <a class="select-option" @click="highlightPlan">Select Plan</a></p>
              <p v-if="selectedSupportPlan"><v-icon color="accent" small class="me-1">fa-check</v-icon>4. <strong>{{selectedSupportPlan.name}}</strong></p>
              <p v-if="!selectedSupportPlan"><v-icon color="grey" small class="me-1">fa-chevron-right</v-icon>4. <a class="select-option" @click="highlightSupport">Select Support Option</a></p>
              <!-- <div v-if="selectedMode=='event'">
                <p v-if="!dataRetention"><v-icon color="grey" small>fa-chevron-right</v-icon> <a class="select-option" @click="highlightDataRetention">Select Data Retention</a></p>
                <p v-if="dataRetention == 'NO'"><v-icon color="grey" small>fa-times</v-icon> No data retention</p>
                <p v-if="dataRetention == 'YES'"><v-icon color="accent" small>fa-check</v-icon> Ongoing data retention</p>
              </div> -->
            </div>

          </v-col>
          <v-col v-if="!hideCalculatorDetails || $vuetify.breakpoint.mdAndUp" cols="4" md="4" class="align-center with-border-left">
            <div v-if="!selectedPlan || !selectedSupportPlan" class="">
              <div class="text-muted">Starting From</div>
              <span class="small mr-1">{{currency}}</span><span class="large">{{minimumFee | int}}</span>
              <span v-if="selectedMode=='subscription'" class="small">per<br/>month</span>
            </div>
            <div v-else-if="selectedMode=='event'" class="">
              <div class="text-muted">Total Fee</div>
              <span class="small mr-1">{{currency}}</span><span class="large">{{totalFee | int}}</span>
              <p v-if="dataRetention == 'YES'" class="small">+ {{currency}} {{getProductPrice('subscription-data-retention') }} per user per month</p>
            </div>
            <div v-else class="">
              <div class="text-muted">One-Time Fee</div>
              <span class="small mr-1">{{currency}}</span><span class="large">{{oneTimeFee | int}}</span>
              <div class="mt-4 text-muted">Monthly Fee</div>
              <span class="small mr-1">{{currency}}</span><span class="large">{{perUserFee*amount | int}}</span>
            </div>
          </v-col>
          <v-col v-if="!hideCalculatorDetails || $vuetify.breakpoint.mdAndUp" cols="12" md="4" class="with-border-left">
            <div>
              <v-btn v-if="plan=='ENTERPRISE'" :to="{name:'contact'}" color="accent" block class="my-0">Talk To Us</v-btn>
              <v-btn v-else :to="createEventRouteInfo" block color="accent" class="my-0">Start Now</v-btn>
              <v-btn v-if="plan=='ENTERPRISE' || totalFee > 1000" outlined block color="accent" class="mt-4 mb-0" @click="requestQuote">Request Quote</v-btn>
            </div>
            <div class="d-none d-md-block">
              <v-divider class="my-4" />
              <v-btn class="mb-4" color="accent" outlined block to="/start-trial">Start Free Trial</v-btn>
              <p class="text-muted text-center">
                Up to 5 users and 14 days. No credit card needed.
              </p>
              <v-divider class="my-4" />
              <v-btn class="" color="accent" text block to="/contact">Talk to Us</v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

      <div class="mt-8 py-8 px-0 grey lighten-5">
        <a name="comparison" style="scroll-margin-top: 80px;"></a>
        <h3 class="px-4">Detailed Feature Comparison</h3>

        <table class="feature-table" style="width: 100%;">
          <thead>
            <tr class="text-align-left" style="text-align:left;">
              <th>&nbsp;</th>
              <th>Starter</th>
              <th>Pro</th>
              <th>Enterprise</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><h4>Platform</h4></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>
                iOS and Android App
                <simple-tooltip text="Users can use our app to manage their profile, check leaderboards, and much more!"/>
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Web platform
                <simple-tooltip text="Rather not use an extra app? No worries, use our web platform instead."/>
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Multiple languages supported
                <simple-tooltip text="Our app and web platform are available in English, Spanish, French, German and more."/>
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Works globally &amp; across all time zones
                <simple-tooltip text="Our platform works globally across time zones."/>
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                <router-link to="/solutions/integrations/fitness-trackers">Fitness Tracker Integrations</router-link>
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Integrated Step &amp; Activity Tracker
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Manual Entry 
                <simple-tooltip text="You can either allow or disallow manual entry for your event."/>
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>

            <tr>
              <td><h4>Challenges</h4></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>
                Step Challenges
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Activity-based Challenges
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Unlimited live leaderboards
                <simple-tooltip text="Add as many leaderboards to your event as you want to. Create weekly leaderboards or split into separate leaderboards per activity type."/>
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Team Leaderboards
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Collective Progress
                <simple-tooltip text="Display the collective result of all participants combined."/>
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Virtual Course Maps
                <simple-tooltip text="Display a virtual course map. Visit your office locations or go on a virtual journey."/>
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Customizable Onboarding Flow
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Activity Conversions
                <simple-tooltip text="Convert between activities and steps for more inclusive challenges."/>
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Custom challenge mode (points &amp; formulas)
                <simple-tooltip text="Create custom challenges based on points or using formulas to accomplish specific challenge goals."/>
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                User-created challenges (subscriptions only)
                <simple-tooltip text="Allow users to create their own challenges and invite their co-workers."/>
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>

            <tr>
              <td><h4>Feed and Chat</h4></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>
                Event Feed with photos, videos, and messages
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Photo and selfie upload
                <simple-tooltip text="Photo upload can be disabled from the admin dashboard."/>
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Feed interactions (like and comment)
                <simple-tooltip text="Feed interactions can be disabled from the admin dashboard."/>
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Liking and Commenting on results
                <simple-tooltip text="Result interactions can be disabled from the admin dashboard."/>
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Friends and one-to-one challenges
                <simple-tooltip text="Friend functionality can be disabled from the admin dashboard."/>
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td><h4>Look 'n feel</h4></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>
                Co-branding in the app
                <simple-tooltip text="Customize the look 'n feel of the app to match your corporate identity."/>
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Add custom content
                <simple-tooltip text="Add custom content (text and images) to the app."/>
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Customize Leaderboard Columns
                <simple-tooltip text="Select the data you want to display on your leaderboards."/>
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Localize Event Content
                <simple-tooltip text="Translate event content into multiple languages."/>
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                <router-link to="/solutions/integrations/custom-app-platforms">White-labeling</router-link>
                <simple-tooltip text="Completely adapt our product to your brand and offer it as your own app or website."/>
              </td>
              <td colspan="3"><router-link to="/contact">Please contact us</router-link></td>
            </tr>

            <tr>
              <td><h4>Easy to use admin dashboard</h4></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>
                Event Management Dashboard
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Send Push-notifications
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Customizable Emails
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Export Detailed Excel Reports
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                (Auto) Lock leaderboards
                <simple-tooltip text="Lock leaderboards to prevent changes after winners are announced."/>
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>

            <tr>
              <td><h4>Integrations</h4></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td><router-link to="/microsoft-teams">Microsoft Teams Integration</router-link></td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td><router-link to="/slack">Slack Integration</router-link></td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Embeddable Leaderboards (SharePoint, website)
                <simple-tooltip text="Embed leaderboards in SharePoint or on your website."/>
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                API
                <simple-tooltip text="Enable advanced integrations using our REST API."/>
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Zapier integration
                <simple-tooltip text="Connect your data sources to us using Zapier zaps."/>
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>

            <tr>
              <td><h4>Security</h4></td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td>
                GDPR &amp; CCPA compliant
                <simple-tooltip text="Privacy of your data is our top priority."/>
              </td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Customizable user-consent question before joining
                <simple-tooltip text="Optionally require users to accept a customizable consent message before they may join an event."/>
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Multiple co-admins
                <simple-tooltip text="Allow multiple co-admins to access the event dashboard."/>
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                More fine-grained access control using roles (RBAC)
                <simple-tooltip text="Assign roles to your co-admins to safe-guard control of access to sensitive settings and data."/>
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Limit access to sensitive (fitness) data to only selected (external) users
                <simple-tooltip text="Ensure only selected (external) users have access to sensitive employee/fitness data."/>
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Single Sign-On
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Enforce Two-factor Authentication
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Custom Data Processing Agreement 
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Custom Security Checklists
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
            <tr>
              <td>
                Choose Your Data Center Location
              </td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="grey">fa-times</v-icon></td>
              <td><v-icon color="green">fa-check</v-icon></td>
            </tr>
          </tbody>
        </table>
      </div>
      <v-divider />

     <div id="page-customers" class="mt-8 blob-bg white--text">
      <div class="container pb-0">
        <v-row>
          <v-col class="pb-4">
            <h1 class="headline">Trusted by global corporations</h1>
            <p>
              {{tenant.name}} is used by world-renowned corporations to host their connected fitness events. Some of our key partners are:
            </p>
            <SectionCustomers :showcase="false" corporate/>
          <v-col class="text-center" cols="12" md="12">
            <h1 class="subtitle">Get started today!</h1>
            <p class="mb-0">
              <strong>{{siteData.stats.total_events | int}}</strong> have been created already by {{siteData.stats.total_orgs | int }} organizations. Join today!
            </p>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" :to="createEventRouteInfo">Create your event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>

          </v-col>
        </v-row>
      </div>
    </div>
    <div v-if="false" class="grey lighten-4">
    <v-container class="pb-0">
      <v-row>
        <v-col cols="4" md="2">
          <img src="https://sodisp.imgix.net/content/quote-drift-kelly.jpg?w=200&h=200&fit=facearea&faceindex=1&facepad=4&mask=ellipse&mask-bg=f5f5f5" style="max-width:100%;" />
        </v-col>
        <v-col cols="8" md="10">
          <blockquote>
            "At Drift we had a goal of 10% participation in our first challenge and had over 20% of our employees signed up. [...] This is by far the smoothest platform I have used to date and our Drifters are already asking when the next challenge will take place."
          </blockquote>
          <p class="mt-8">
            Kelly Reardon<br/>
            <i>Drift Experience Manager @ <a href="https://drift.com" target="_blank">Drift</a></i>
          </p>
        </v-col>
      </v-row>
      <v-divider/>
      <v-row>
        <v-col cols="4" md="2">
          <img src="https://sodisp-ugc.imgix.net/e_ecratchit-step-challenge-2022_logo_20220505204729?w=200&h=200&mask=ellipse&mask-bg=f5f5f5" style="max-width:100%;" />
        </v-col>
        <v-col cols="8" md="10">
          <blockquote>
            "We are loving our Step Challenge! It is a huge hit!"
          </blockquote>
          <p class="mt-8">
            Gail Miller<br/>
            <i>Staff Administrator @ <a href="https://ecratchit.com" target="_blank">eCratchit</a></i>
          </p>
        </v-col>
      </v-row>
    </v-container>
    </div>

    <div class="faqs mt-8">
      <h1 class="headline">Frequently Asked Questions</h1>
 
      <h3 class="subtitle">Can I try before I buy?</h3>
      <p>Sure! Our <router-link :to="{name: 'startTrial'}">Free trial</router-link> contains the basic features to get you started and try out with 5 team members. Once you are convinced you can easily upgrade to a paid plan.</p>

      <h3 class="subtitle">Can I add more user credits later on?</h3>
      <p>Of course. You can always add more user credits when you reach your maximum. We'll send you a notification before you reach the capacity so you have time to top-up.</p>

      <h3 class="subtitle">What is an 'event'?</h3>
      <p>An event on our platform represents a fitness challenge. It has a name and start and end dates. A single event may have one or more leaderboards active at the same time, or you may add leaderboards that are only available during a limited time, such as weekly or monthly leaderboards.</p>

      <h3 class="subtitle">Are preparation time and wrap-up excluded from the active days?</h3>
      <p>Yes. The active event days is the number of days the event is active. You have time before and after to prepare and wrap-up the event without impacting your available event duration.</p>

      <h3 class="subtitle">Can I have a one-time event longer than 45 days?</h3>
      <p>Yes. You can request an extended duration (at double the price as listed above) for events with a duration between 45 and 100 days. For events over 100 days you can use our Unlimited plans.</p>

      <h3 class="subtitle">Is pricing available in EUR or GBP?</h3>
      <p>Yes, you can select your currency on top of the pricing page. Please <router-link to="contact">contact us</router-link> for other currencies.</p>

      <h3 class="subtitle">What payment methods are available?</h3>
      <p>By default you can pay using credit card. <router-link to="contact">Reach out to us</router-link> to enable ACH, IBAN or wire transfer payments.</p>

      <h3 class="subtitle">How can I cancel my payment?</h3>
      <p>You can try <router-link :to="{name: 'startTrial'}">a free trial</router-link> before you buy. There is no cancellation possible after a payment is completed.</p>

      <h3 class="subtitle">Can I get a refund?</h3>
      <p>If you're not 100% convinced with out platform you can always get a refund for per-user fees. The only condition is that your event hasn't started yet. </p>

      <h3 class="subtitle">Do you offer subscription model for a monthly or yearly fitness program?</h3>
      <p>Yes, we do! Select the Unlimited option to see our subscription plans.</p>

      <h3 class="subtitle">Any other question?</h3>
      <p>We'd <router-link to="contact">love to hear from you</router-link>.</p>
    </div>


    <div class="secondary white--text py-6 py-md-12 mt-4">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h1 class="subtitle">Schedule a call to discuss pricing for <u>your</u> corporate fitness event</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4 mr-4" color="white" to="/request-demo">Request Demo</v-btn>
              <v-btn class="mb-4 mr-4" color="white" outlined to="/features">Explore features</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>


  </div>
</template>


<script>
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import BigButton from "@/components/BigButton.vue";
import SimpleTooltip from "@/components/SimpleTooltip.vue";
import PriceCalculatorDialog from "@/components/PriceCalculatorDialog.vue";
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Contact",
  components: {
    SectionIntegrations,
    SectionCustomers,
    BigButton,
    SimpleTooltip,
    PriceCalculatorDialog,
  },
  props: {
  },
  data: function() {
    return {
      siteData: siteData,
      tenant: tenant,
      products: siteData.products,
      showPlanDetails: false,
      selectedMode: 'event',
      plan: null,
      amount: 100,
      support: null,
      dataRetention: null,
      currency: 'USD',
      currencies: siteData.currencies,
      hideInlineCalculator: false,
      hideCalculatorDetails: true,
    };
  },
  mounted() {
    if (this.$route.query.participants) {
      this.amount =  this.$route.query.participants;
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll (event) {
      this.hideInlineCalculator = this.$refs.inlineCalculator && window.scrollY > this.$refs.inlineCalculator.offsetTop-300;
      //console.log('///scroll', window.scrollY, this.hideInlineCalculator);
    },
    getProductPrice(productKey) {
      var product = this.products.find(x=>x.key==productKey)
      if (!product) {
        return null;
      }
      return product.prices[this.currency];
    },
    totalUserFee(product) {
      if (!product) {
        return null;
      }
      return product.prices[this.currency] * this.amount;
    },
    totalPrice(product) {
      if (!product) {
        return null;
      }
      return this.oneTimeFee + this.totalUserFee(product);
    },
    async highlightMode() {
      await this.highlightElement(this.$refs.modeSection);
    },
    async highlightUsers() {
      await this.highlightElement(this.$refs.usersSection);
    },
    async highlightPlan() {
      await this.highlightElement(this.$refs.planSection);
    },
    async highlightSupport() {
      await this.highlightElement(this.$refs.supportSection);
    },
    async highlightDataRetention() {
      await this.highlightElement(this.$refs.dataRetentionSection);
    },
    async highlightElement(elem) {
      await elem.scrollIntoView({ behavior: "smooth"});
      elem.classList.add('yellow');
      setTimeout(() => {
        if (elem) elem.classList.remove('yellow');
      }, 500);
    },
    requestQuote() {
      const data = { 
        comments: `Quote Request (${this.selectedMode})
Plan: ${this.selectedPlan ? this.selectedPlan.name : '- not selected -'} for ${ this.amount } users
Support Option: ${this.selectedSupportPlan ? this.selectedSupportPlan.name : '- not selected -'}
Question: <please include any specific questions you might have here>`,
      };
      this.$router.push({ name: 'contact', query: data });
    },
  },
  computed: {
    createEventRouteInfo() {
      return {name:'eventmanagerCreate', query: {
        mode: this.selectedMode, 
        license: this.plan, 
        participants: this.amount, 
        currency: this.currency, 
        support: this.selectedSupportPlan ? this.selectedSupportPlan.key.replace('product-','') : '' 
      }};
    },
    selectedPlan() {
      if (this.selectedMode == 'event') {
        return this.plan && this.products.find(x=>x.key==`product-event-${this.plan.toLowerCase()}`);
      }
      return this.plan && this.products.find(x=>x.key==`subscription-month-${this.plan.toLowerCase()}`);
    },
    selectedSupportPlan() {
      if (this.support == 'SELF_SERVICE') {
        return this.products.find(x=>x.key=='product-self-service');
      }
      if (this.support == 'SETUP_SERVICE') {
        return this.products.find(x=>x.key=='product-setup-service');
      }
      return this.support && this.products.find(x=>x.key=='product-full-service');
    },
    perUserFee() {
      if (!this.selectedPlan) {
        return null;
      }
      return this.selectedPlan.prices[this.currency];
      var retentionFee = this.dataRetention ? 0.25 : 0;
      return baseFee + retentionFee;
    },
    oneTimeFee() {
      if (!this.selectedSupportPlan) {
        return 0;
      }
      return this.selectedSupportPlan.prices[this.currency];
    },
    totalFee() {
      return this.oneTimeFee + (this.perUserFee*this.amount);
    },
    minimumFee() {
      var product = this.selectedPlan;
      if (!product) {
        if (this.selectedMode == 'event') {
          product = this.products.find(x=>x.key==`product-event-basic`);
        }
        else {
          product = this.products.find(x=>x.key==`subscription-month-basic`);
        }
      }
      var minimumPerUserFee = product.prices[this.currency];

      return (minimumPerUserFee*this.amount);
    },
  },
  watch: {
    plan(val) {
      if (val === 'ENTERPRISE' && (!this.support || this.support == 'SELF_SERVICE')) {
        this.support = 'SETUP_SERVICE';
      }
    },
    support(val) {
      if (this.plan === 'ENTERPRISE' && val == 'SELF_SERVICE') {
        this.support = 'SETUP_SERVICE';
      }
    },
  }
};
</script>

<style lang="scss" scoped>
  .small { font-size: 75%; display: inline-block; text-align: left;}
  .large { font-size: 200%;}
  .large + .small { margin-left: 5px; }

  .pricing-details {
    h3 { margin: 20px 0; }
    .v-btn { margin: 20px 0; }
    ul { text-align: left;;}
    .popular { border: solid 5px #00B46E !important; }
    .plan {
      position: relative;
      border: solid 5px #ccc;
      cursor: pointer;

    }
    hr { margin: 10px 0; border-top-width: 0; border-bottom-color: #00000055;}
  }
  .feature-table {
    tr {
      th { text-align: center; }
      td { 
        text-align: center;
        padding: 8px 4px; 
        border-bottom: solid #fff 1px;
      }
      td h4 { margin-top: 8px;}
      td:first-child { padding-left: 16px; text-align: left; }
      td:last-child { padding-right: 16px; }
      th:nth-child(3), td:nth-child(3) { background-color: white; }
    }

  }

  .price-summary {
    a.select-option { color: #777; text-decoration: underline; text-decoration-style: dotted; }
    .features {
      .v-icon {min-width: 20px;}
    }
    .with-border-left { 
      border-left: 1px solid #ccc;
    }
  }
  .price-side-bar {
    position: fixed;
    bottom: 8px;
    right: 8px;
    z-index: 7;
    max-width: 210px;
    font-size: 90%;
  }
  
  .price-component-container {
    display: flex;
    justify-content: center;
    align-items: stretch !important;
    flex-wrap: wrap;
    gap: 20px;
    font-size: 90%;
    .price-component {
      padding: 12px;
      max-width: 200px;
      text-align: center;
      cursor: pointer;
      position: relative;
      border-radius: 4px;

      h3 { margin-top: 16px; margin-bottom: 8px;}
      .v-icon { margin-bottom: 8px; font-size: 1em; }
    }
    .most-popular { position: absolute; top: -16px; left: 50%; transform: translate(-50%, 0); }

    .selected-marker {
      display: none;
      position: absolute;
      right: 2px;
      top: 2px;
      ///background-color: white;
      //transform:rotate(-45deg);
      &::before {
        display: block;
        content: '';
        width:0;
        height:0;
        border-top:15px solid #fff;
        border-left:15px solid transparent;
        border-right:15px solid transparent;
      }
      &::after {
        display: block;
        content: '';
        width:0;
        height:0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-right: 15px solid #fff;
        margin-left: 15px;
        margin-top: -37px;
      }
      .v-icon { position: relative; top: -15px; right: -8px;}
    }
    .selected{
      background-color: var(--v-primary-lighten2) !important;
      .selected-marker { display: block; }
    }
  }
  @media (max-width: 959px) {
    .v-application .price-summary-bottom {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 7;
      font-size: 90%;
      border-top: 2px solid var(--v-accent-base) !important  ;
      .section-title { padding: 0; vertical-align: middle; }
      p { margin-bottom: 0;}
      .features .v-icon { display: none;}
    }
  }

  .scroll-section { scroll-margin-top: 50px; }

  .v-sheet.dotted, button.dotted { border: 2px dotted #ccc; border-radius: 4px; background: white; }
  button.dotted { border: 1px dotted var(--v-primary-base); }
  
  .faqs {
    h3 { margin-top: 1.5em; }
  }
  .indent { margin: 0 5%; }

</style>